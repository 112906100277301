<!-- =========================================================================================
    File Name: Subscription.vue
    Description: Subscription Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="page-subscription-view">

    <vs-alert :active.sync="subscription_not_found" color="danger" title="Subscription Not Found">
      <span>Subscription record with id: {{ $route.params.subscriptionId }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{name:'page-subscription-list'}"
                     class="text-inherit underline">All Subscriptions</router-link>
      </span>
    </vs-alert>

    <div id="subscription-data" v-if="subscription_data">

      <vx-card :title="`Subscription Actions`" class="mb-base" style="min-height:60px;">

        <vs-button v-if="subscription_data.status !== 'canceled'" @click="cancelSubscription" class="mr-4" icon="icon-x-circle" icon-pack="feather" size="small">
          Cancel Subscription
        </vs-button>
      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Information">
            <table>
              <tr>
                <td class="font-semibold">Status</td>
                <td>{{ subscription_data.status | title }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Latest Invoice</td>
                <td v-if="!subscription_data.latest_invoice_id"><i>unknown</i></td>
                <td v-else>
                  <router-link :to="`/invoices/${subscription_data.latest_invoice_id}`">
                    {{ subscription_data.latest_invoice_id }}
                  </router-link>
                </td>
              </tr>
            </table>
          </vx-card>
        </div>
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Dates / Times">
            <table>
              <tr>
                <td class="font-semibold">Created</td>
                <td>{{ new Date(subscription_data.created * 1000) | date(true) }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Period Start</td>
                <td>{{ new Date(subscription_data.current_period_start * 1000) | date(true) }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Period End</td>
                <td>{{ new Date(subscription_data.current_period_end * 1000) | date(true) }}</td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card class="mb-base" title="Subscribed Products">
            <table>
              <tr v-for="(product, index) in this.product_data">
                <td class="font-semibold">Product</td>
                <td>{{ product.name }}</td>

                <td>&nbsp;</td>

                <td class="font-semibold">USD</td>
                <td>{{ product.amount | currency }}</td>

                <td>&nbsp;</td>

                <td class="font-semibold">w/ access for </td>
                <td>{{ `${product.interval_count} ${product.interval}${product.interval_count === 1 ? '' : 's'}` }}</td>
              </tr>
            </table>
          </vx-card>
        </div>


      </div>

    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      subscription_data: null,
      subscription_not_found: false,
      subscriptionId: this.$route.params.subscriptionId,
      data: {},
      product_data: [],
    };
  },
  computed: {},
  methods: {
    cancelSubscription() {

      this.$vs.loading();

      this.$http.delete(`billing/@me/subscriptions/${this.subscriptionId}`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 204) {
            setTimeout(() => this.$router.push(`/subscriptions`), 2500);

            return this.$vs.notify({
              color: 'success',
              title: 'Subscription Cancelled',
              text: 'Your subscription has been successfully cancelled.',
              position: 'top-right',
            });
          }

        })
        .catch(exception => {
          this.$vs.loading.close();
          return this.$vs.notify({
            color: 'danger',
            title: 'Failed to cancel subscription',
            text: 'An error occurred while cancelling your subscription',
            position: 'top-right',
          });
        });

    },
    loadSubscriptionData() {

      this.$http.get(`billing/@me/subscriptions/${this.subscriptionId}`)
        .then(response => {
          if (response.status === 200) {
            this.subscription_data = response.data.data;

            this.subscription_data.price_ids.forEach(priceId => {

              this.$http.get(`billing/products/unknown/prices/${priceId}`)
                .then(async response => {

                  if (response.status === 200) {
                    const data = response.data.data;

                    const a = await this.$http.get(`billing/products/${data.product_id}`);

                    this.product_data.push({
                      id: priceId,
                      name: a.data.data.name,
                      amount: data.amount,
                      interval: data.interval,
                      interval_count: data.interval_count,
                    });

                  }

                });

            });

          }
        })
        .catch(exception => {

        });

    },
  },
  components: {},
  mounted() {
    this.loadSubscriptionData();
  },
};
</script>

<style lang="scss">
@media print {
  .subscription-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }

    #subscription-container,
    #subscription-container * {
      visibility: visible;
    }

    #subscription-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}

@page {
  size: auto;
}
</style>
